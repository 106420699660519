import React from "react"
import { graphql } from "gatsby"
import Layout from "components/layout"
import { Container, Section } from "../components/Layouts"
import SEO from "components/seo"
import { Image, Text } from "../components/Core"
import UpRightArrow from "../../static/images/up-right-arrow.svg"
import Contact from "../components/Services/contact"
import TheaterVideo from "../components/Theater/TheaterVideo"

const Reviews = ({ data }) => {
  const post = data.allUniquePagesJson.nodes[0]
  const { heading, clientsList } = post

  return (
    <Layout navSpacer className="reviews">
      <SEO title={post.metaTitle} description={post.metaDescription} />

      <Section zeroTop>
        <Container medium>
          <div className="reviews__hero">
            <Text as="h1" text={heading} />
            <UpRightArrow />
          </div>
        </Container>
      </Section>

      <Container>
        {clientsList.map((row, i) => (
          <React.Fragment key={i}>
            <Section>
              <div className="reviews__row">
                <div>
                  <div className="is-hidden-touch">
                    <Image
                      useAR={false}
                      publicId={row.desktopImage}
                      className="rounded"
                    />
                  </div>
                  <div className="is-hidden-desktop">
                    <Image useAR={false} publicId={row.mobileImage} />
                  </div>
                </div>
                <div>
                  <Text className="mb-1" as="h2" text={row.clientName} />
                  <Text text={row.blurb} />
                  {row.code && (
                    <TheaterVideo
                      videoUrl={
                        row.type === "wistia"
                          ? row.code
                          : `https://www.youtube.com/watch?v=${row.code}`
                      }
                      language={"en"}
                      buttonClass="contained mb-2"
                    />
                  )}
                </div>
              </div>
            </Section>
          </React.Fragment>
        ))}
      </Container>

      <Section zeroBottom>
        <Container medium>
          <Contact />
        </Container>
      </Section>
    </Layout>
  )
}

export default Reviews

export const pageQuery = graphql`
  query reviewsPage($title: String!) {
    allUniquePagesJson(filter: { title: { eq: $title } }) {
      nodes {
        metaTitle
        metaDescription
        heading
        clientsList {
          clientName
          blurb
          desktopImage
          mobileImage
          type
          code
        }
      }
    }
  }
`
